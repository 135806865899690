<template>
  <ion-app>
    <ion-menu side="start" content-id="main" type="overlay">
      <ion-header>
        <ion-toolbar>
          <ion-title>Nearby Renting</ion-title>
        </ion-toolbar>
      </ion-header>

      <ion-content>
        <div v-if="isAdminView">
          <!-- <ion-item @click="closer()" button router-link="/my-offers">
            <ion-icon class="menu-icon" :icon="listOutline"></ion-icon>
            My Offers
          </ion-item>
          <ion-item @click="closer()" button router-link="/search">
            <ion-icon class="menu-icon" :icon="searchOutline"></ion-icon>
            Search
          </ion-item>
          <ion-item @click="closer()" button router-link="/advanced-search-edit">
            <ion-icon class="menu-icon" :icon="optionsOutline"></ion-icon>
            Advanced Search
          </ion-item>
          <ion-item button @click="closer()" router-link="/my-profile">
            <ion-icon class="menu-icon" :icon="personOutline"></ion-icon>
            Profile
          </ion-item> -->
          <mq-responsive target="xss-xs">
            <ion-item
              button
              @click="
                handlePageToShow('neighbourhoodAdvisor');
                closer();
              "
            >
              Neighbourhood Advisor
              <!-- <ion-icon class="menu-icon" :icon="alertCircleOutline"></ion-icon>Feedback -->
            </ion-item>
            <ion-item
              button
              @click="
                handlePageToShow('analyze');
                closer();
              "
            >
              Analyze
              <!-- <ion-icon class="menu-icon" :icon="alertCircleOutline"></ion-icon>Feedback -->
            </ion-item>
            <ion-item
              button
              @click="
                handlePageToShow('listings');
                closer();
              "
            >
              Listings
              <!-- <ion-icon class="menu-icon" :icon="alertCircleOutline"></ion-icon>Feedback -->
            </ion-item>
            <ion-item
              button
              @click="
                handleOpen('preferences');
                closer();
              "
              >Peferences
              <!-- <ion-icon class="menu-icon" :icon="alertCircleOutline"></ion-icon>Feedback -->
            </ion-item>
            <ion-item
              button
              @click="closer()"
              href="https://forms.gle/dCWLJvedickRtT388"
              target="_blank"
            >
              <ion-icon class="menu-icon" :icon="alertCircleOutline"></ion-icon>Feedback
            </ion-item>
          </mq-responsive>
          <mq-responsive target="sm+">
            <ion-item
              button
              @click="closer()"
              href="https://forms.gle/dCWLJvedickRtT388"
              target="_blank"
            >
              <ion-icon class="menu-icon" :icon="alertCircleOutline"></ion-icon>Feedback
            </ion-item>
          </mq-responsive>
        </div>
        <div v-else>
          <ion-item>
            <svg-icon
              :size="24"
              type="mdi"
              class="ion-margin-horizontal"
              :path="mdiSortAscending"
            ></svg-icon>
            <ion-select
              color="dark"
              placeholder="Sort Ascending"
              :interface-options="customAlertOptions"
              interface="alert"
              @ionChange="sortAdsAndCloseMenu($event)"
              @ionDismiss="closer()"
            >
              <ion-select-option value="totalRent">Rent (EUR)</ion-select-option>
              <ion-select-option value="train">Train/Bus (min.)</ion-select-option>
              <ion-select-option value="walking">Walking (min.)</ion-select-option>
              <ion-select-option value="bicycle">Bicycle (min.)</ion-select-option>
              <!-- <ion-select-option value="car">Car (min.)</ion-select-option> -->
            </ion-select>
          </ion-item>
        </div>
      </ion-content>
      <div v-if="isAdminView">
        <!-- Footer: Log Out -->
        <ion-footer>
          <ion-item button @click="closer() & doLogout()">
            <ion-icon class="menu-icon" :icon="exitOutline" color="danger"></ion-icon
            ><ion-text color="danger">Log out</ion-text>
          </ion-item>
        </ion-footer>
      </div>
    </ion-menu>
    <ion-router-outlet id="main" />
  </ion-app>
</template>

<script>
import { MqResponsive } from "vue3-mq";
import { useInboundMarketingStore } from "@/store/inboundMarketing";
import { mapActions } from "pinia";
import { useSearchStore } from "@/store/search";
// Material design icons
import SvgIcon from "@jamescoyle/vue-icon";
import { mdiSortAscending } from "@mdi/js";
// import { mapActions } from "pinia";
import { useRouter } from "vue-router";
import { useAuthStore } from "@/store/auth";
// import { useSearchStore } from "@/store/search";
import {
  alertCircleOutline,
  exitOutline,
  listOutline,
  optionsOutline,
  personOutline,
  searchOutline,
} from "ionicons/icons";
import {
  IonApp,
  IonContent,
  IonFooter,
  IonHeader,
  IonIcon,
  IonItem,
  IonMenu,
  IonRouterOutlet,
  IonSelect,
  IonSelectOption,
  IonText,
  IonTitle,
  IonToolbar,
  menuController,
} from "@ionic/vue";
export default {
  name: "App",
  components: {
    IonApp,
    IonContent,
    IonFooter,
    IonHeader,
    IonIcon,
    IonItem,
    IonMenu,
    IonRouterOutlet,
    IonSelect,
    IonSelectOption,
    IonText,
    IonTitle,
    IonToolbar,
    MqResponsive,
    SvgIcon,
  },
  data() {
    return {
      mdiSortAscending,
    };
  },
  setup() {
    const customAlertOptions = {
      header: "Sort Ascending",
      message: "Choose only one",
      translucent: true,
    };
    const { logoutUser, user, profile } = useAuthStore();
    const router = useRouter();
    const doLogout = async () => {
      await logoutUser();
      router.push({ path: "/login", replace: true }); // "replace:true" -> It navigates without pushing a new history entry
    };
    return {
      customAlertOptions,
      doLogout,
      user,
      profile,
      alertCircleOutline,
      exitOutline,
      listOutline,
      optionsOutline,
      personOutline,
      searchOutline,
    };
  },
  computed: {
    isAdminView() {
      // const router = useRouter();
      // Define your admin routes here
      const adminRoutes = [
        "/my-offers",
        "/my-profile",
        "/new-ad",
        "/advanced-search-edit",
        "/housing-finder", //Adding this for now and comenting from line 12 to 27. Only want to have feedback and logout option.
      ];
      // Check if the current route starts with any of the defined adminRoutes or the dynamic edit route
      return (
        adminRoutes.some((route) => this.$route.path.startsWith(route)) ||
        this.$route.path.startsWith("/edit-ad/")
      );
    },
  },
  methods: {
    ...mapActions(useInboundMarketingStore, ["handlePageToShow", "handleOpen"]),
    ...mapActions(useSearchStore, ["sortAds"]),
    sortAdsAndCloseMenu(ev) {
      this.sortAds(ev);
      this.closer();
    },
    closer() {
      menuController.close("start");
    },
  },
};
</script>
<style>
/* Menu CSS */
.menu-icon {
  margin: 0px 10px 0px 0px;
  font-size: 18px;
}
</style>
