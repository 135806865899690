import { createApp } from 'vue'
import { createPinia } from 'pinia'
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate'
import App from './App.vue'
// Material design installation: https://stackoverflow.com/questions/51792391/vue-cli-3-material-design-icons-installation
// https://www.npmjs.com/package/vue-material-design-icons
// list of available icons: https://pictogrammers.com/library/mdi/
// import 'vue-material-design-icons/styles.css'
import router from './router';

import { IonicVue } from '@ionic/vue';

/* Core CSS required for Ionic components to work properly */
import '@ionic/vue/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/vue/css/normalize.css';
import '@ionic/vue/css/structure.css';
import '@ionic/vue/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/vue/css/padding.css';
import '@ionic/vue/css/float-elements.css';
import '@ionic/vue/css/text-alignment.css';
import '@ionic/vue/css/text-transformation.css';
import '@ionic/vue/css/flex-utils.css';
import '@ionic/vue/css/display.css';

/* Theme variables */
import './theme/variables.css';

/* Screensize */
import { MqResponsive, Vue3Mq } from 'vue3-mq';

// import { useAuthStore } from "./store";

const pinia = createPinia()
pinia.use(piniaPluginPersistedstate)
// Above the createApp() line
// Camera
// Importing PWA Elements
import { defineCustomElements } from '@ionic/pwa-elements/loader';
// Defining PWA elements on window object
defineCustomElements(window);



const app = createApp(App)
  .use(IonicVue)
  .use(pinia)
  .use(router);

/* Screen size breakpoints */
app.use(Vue3Mq, {
  breakpoints: {
    xss: 0,
    xs: 520,
    sm: 780,
    md: 1040,
    lg: 1300,
    xl: 1920,
  }
})

app.component('MqResponsive', MqResponsive)

router.isReady().then(() => {
  app.mount('#app');
});

// // get the store
// const store = useAuthStore();

// // initialize auth listener to see if we
// // have a user at startup
// store.initializeAuthListener().then(() => {
//   app.use(router).mount("#app");
// });