import {
  balloonOutline,
  barbellOutline,
  cartOutline,
  cashOutline,
  gridOutline,
  trainOutline,
} from 'ionicons/icons';
import HTTP from "@/api/index.js";
import { defineStore } from 'pinia'
import { useAuthStore } from "@/store/auth";
// Temporality adding for logIn action

// You can name the return value of `defineStore()` anything you want,
// but it's best to use the name of the store and surround it with `use`
// and `Store` (e.g. `useUserStore`, `useCartStore`, `useProductStore`)
// the first argument is a unique id of the store across your application
export const useInboundMarketingStore = defineStore('useInboundMarketingStore', {
  // arrow function recommended for full type inference
  state: () => ({
    minPurchasePrice: 2500, //1500
    maxPurchasePrice: 10000, //13000
    minRentPrice: 10, //7
    maxRentPrice: 21, //25
    setSelectedPreferenceView: "",
    showLoadingDestinationAddress: false,
    destinationAddressNotFoundAlert: false,
    destinationName: "",
    destinationAddress: "",
    segmentCommuteDestination: "searchcd",
    housingAddressAnalyzed: "",
    sortValue: "",
    showModalApplyListing: null,
    userButtonSelection: "analyze",
    listingsData: null,
    selectedButton: "",
    showLoading: false,
    showLoadingAnalyzer: false,
    addressNotFoundAlert: false,
    neighbourhoodsNotFoundAlert: false,
    publishAdAlert: false,
    isOpenPreferenceModal: false,
    isAreaSummaryEnabled: true, // Boolean to track toggle state
    clickedAnalyzeNeighbouhood: false,
    showAddressAnalyzerAccordion: true,
    polylineDataAnalyzer: [],
    polylineDataRecommender: [],
    isResultsPolyline: false,
    isRecommendedPolyline: false,
    areaSummary: "",
    areaPolygon: [],
    recommendedNeighbourhoods: [],
    recommendedNeighbourhoodsLow: [],
    recommendedNeighbourhoodsHigh: [],
    recommendedAreaPolygon: [],
    recommendedAreaCenter: {},
    recommendedAreaSummary: "",
    recommendedAreaVillageSubdivision: "",
    recommendedAreaCityTown: "",
    // Travel times by mean of transportation to recommended area
    recommendedAreaTrainTimeToReference: null,
    recommendedAreaBicycleTimeToReference: null,
    recommendedAreaCarTimeToReference: null,
    recommendedAreaWalkingTimeToReference: null,
    showFullText: false,
    // [{ cityTown: "Frankfurt am Main", villageSubdivision: "Gallus", neighbourhoodDescription: "Gallus is a small village located within the municipality of Büdingen in Hessen, characterized by its rural charm and low population density. The area offers a peaceful environment, surrounded by nature, which is ideal for those seeking tranquility away from urban life. However, amenities such as shopping, healthcare, and entertainment are limited, requiring residents to travel to nearby towns for more extensive services. The community is primarily residential, with a mix of older and newer homes, but it may lack some modern infrastructure. Overall, it is suitable for those looking for a quiet lifestyle, but may not meet the needs of individuals who prefer a more vibrant and accessible urban setting.", rentLowPrice: 15, rentHighPrice: 14, purchaseLowPrice: 3000, purchaseHighPrice: 5000, center: { lat: 50.1104436, lng: 8.6823506 } },
    // { cityTown: "Frankfurt am Main", villageSubdivision: "Ostend", neighbourhoodDescription: "Ostend, located in the municipality of Büdingen in Hessen, is a small, tranquil village known for its picturesque landscapes and rural charm. The area features a mix of traditional half-timbered houses and modern residences, providing a serene environment for families and retirees. While the community is close-knit and offers a peaceful lifestyle, amenities such as shops and schools are limited, which may require residents to travel to nearby towns for their daily needs. The region is surrounded by nature, making it suitable for outdoor activities but may lack cultural and entertainment options. Overall, Calbach is ideal for those seeking a quiet, rural lifestyle but may not be suitable for individuals looking for vibrant urban amenities.", rentLowPrice: 20, rentHighPrice: 25, purchaseLowPrice: 5000, purchaseHighPrice: 7000, center: { lat: 50.1007301, lng: 8.5722132 } }],
    payloadAmenityClosestCalculation: "",
    stationChips: [{
      type: "Sbahn",
      description: "The S-Bahn is a rapid transit railway system in Germany, connecting major cities with suburban and regional areas. It offers frequent, reliable service, making it ideal for commuters and travelers needing quick access to urban centers. The S-Bahn is known for its efficiency and integration with other public transport networks.",
      tags: "rapid, reliable, commuter, urban, efficient"
    },
    {
      type: "Ubahn",
      description: "The U-Bahn is an underground subway system in Germany, primarily serving urban areas and providing fast, frequent transit across cities. It is ideal for commuters and tourists looking for efficient and convenient travel within city limits. The U-Bahn is known for its punctuality, extensive network, and easy access to key locations.",
      tags: "subway, fast, urban, efficient, convenient"
    },
    {
      type: "Strassebahn",
      description: "The Straßenbahn, or tram, is a street-level light rail system in Germany that operates primarily in urban and suburban areas. It provides a convenient and eco-friendly mode of transport, offering frequent service with easy access to city centers and neighborhoods. Straßenbahns are ideal for short to medium distances and are integrated into the wider public transport network.",
      tags: "tram, urban, eco-friendly, convenient, frequent"
    }],
    stationChipsSelected: [],
    meanTransportationAvailableChips: ["Bike", "Walk", "Car", "Train/Bus"], // "Train/Bus"
    selectedMode: "",
    filteredChips: [],
    chipSearchInput: "",
    currentStationsDiscoveryData: [],
    sbahnStations: [],
    ubahnStations: [],
    strassebahnStations: [],
    // Ammenities
    selectedChips: [],  // Array to hold the selected chip labels
    selectedCategory: "Transport", // Default to the first category
    chipItems: [
      {
        category: "Transport",
        icon: trainOutline,
        items: [

          "Sbahn",
          "Ubahn",
          "Strassebahn",
          // "Bus station", To do. We only have Sbahn, Ubahn and Strassebahn in the db.
          "Gas station",
          "Charging station - Car",
          "Charging station - Bicycle",
          "Bicycle parking",
          // "Parking - car", // Parking and parking_entrance contain data in open street map. Merge. We dont need. Finding an apartment usually says if it includes parking or not.
        ]
      },
      {
        category: "Supermarket",
        icon: cartOutline,
        items: [
          "Rewe",
          "Penny",
          "Tegut",
          "Alnatura",
          "Aldi",
          "Lidl",
          "Edeka",
          "Nahkauf",
          "Denns BioMarkt",
          "Netto Marken-Discount",
          "Kaufland",  // Added
          "Norma"     // Added
        ]
      },
      {
        category: "Gym",
        icon: barbellOutline,
        items: [
          "Kieser Training",
          "Fitness First",
          "McFit",
          "Bodystreet",
          "Fit seven eleven",
          "Clever fit",
          "Body Shape Fitness",
          "San-Fit",
          "Prime Time Fitness",
          "Körperformen",
          "FitX",
          "Mrs. Sporty",
          "EMS Lounge",
          "Fitness Level",
          "Fitbox",
          "Ixmal",
          "Kraftwerk",
          "INJOY",
          "EasyFitness",
          "Fitnessfabrik",
          "Body Culture",

        ]
      },
      {
        category: "ATM",
        icon: cashOutline,
        items: [
          "Euronet",
          "Postbank",
          "Frankfurter Volksbank",
          "Sparkasse",
          "Deutsche Bank",
          "ING",
          "Volksbank Mittelhessen",
          "Mainzer Volksbank",
          "Reisebank",
          "Sparda-Bank",
          "Commerzbank",
          "Targobank",
          "Degussa Bank",
          "Santander",
          "FinanzPunkt",
          "Raiffeisenbank",
          "Volksbank",
          "Sparda-Bank Hessen",
          "Naspa",
          "Wiesbadener Volksbank",
          "HypoVereinsbank",
          "VR Bank Main-Kinzig-Büdingen"
        ]
      },
      {
        category: "Leisure",
        icon: balloonOutline,
        items: [
          "Cinema",
          "Theatre",
          "Bar",
          "Biergarten",
          "Casino",
          "Arts centre",
          "Public bookcase",
          // "Park" To do: how to get parks! MUST!
          // Park are no good data. But maybe the use can select a place for theirself, drop a pin and add name as their reference.

        ]
      },
      {
        category: "Other",
        icon: gridOutline,
        items: [
          "Pharmacy",
          "Recycling container",
          "Post box",
          "Post office",
          "Nursing home",
          "Bakery", // Might need to create another category that shows the brand. Germans might not forgive that I didnt add a brand haha.
          "Kiosk",
          "Laundry",
          "Dry cleaning",
          "Park"
        ]
      }
    ],
    // Define speeds in meters per second
    averageWalkingSpeed: 1.4,
    averageBicycleSpeed: 4.3,
    averageCarSpeed: 12.5,
    referencesResults: [],
    mapZoom: 13,
    initialMapCenter: [50.110924, 8.682127],
    mapCenter: [],
    housingAddress: "",
    minRentPerSqM: 0, // Example value for min rent per square meter
    maxRentPerSqM: 0, // Example value for max rent per square meter
    minPurchasePerSqM: 0, // Example value for min purchase price per square meter
    maxPurchasePerSqM: 0, // Example value for max purchase price per square meter
    cityOrTown: "",
    villageOrSubdivision: "",
    inputArea: null, // The area input by the user
    maxPrice: null, // maxPrice for recommendation
    maxTravelTime: null, //maxTravelTime for recommendation
    rentVsPurchaseThreshold: 10000, // Price rentVsPurchaseThreshold to determine if it's rent or purchase
    refCoordLat: 0,
    refCoordLng: 0,
    // References time initializing
    timeBikeToReference: 0,
    timeTrainToReference: 0,
    timeWalkingToReference: 0,
    timeCarToReference: 0,
    // Ammenities time initializing
    timeBikeToAmenity: 0,
    timeTrainToAmenity: 0,
    timeWalkingToAmenity: 0,
    timeCarToAmenity: 0,
    // Example just to get the amenity match
    dummyAmenity: [
      {
        name: "Sbahn",
        coords: {
          lat: 50.053194,
          lng: 8.665891,
        }
      },
      {
        name: "Rewe",
        coords: {
          lat: 50.052671,
          lng: 8.668965,
        }
      },
      {
        name: "Kieser Training",
        coords: {
          lat: 50.054984,
          lng: 8.691542,
        }
      },
      {
        name: "Deutsche Bank",
        coords: {
          lat: 50.049233,
          lng: 8.695229,
        }
      },
      {
        name: "Recycling containers",
        coords: {
          lat: 50.054386,
          lng: 8.679276,
        }
      }
    ],
    housingMarkerArray: [],
    googleMapsView: "",
    minFromDate: "",
    defaultFromDate: "",
    maxFromDate: "",
    referencesFrankfurt: [],
    results: [],
    // User's search selections is saved here to call API after
    searchParams: {
      reference: "",
      meanTransportation: "",
      travelTime: "",
      housingType: "",
      maxRent: 0,
    },

    // Populates macro and micro map
    referenceCoord: [],
    zoomMacroMap: 12,
    avgRent: 0,
    marketingReference: "Frankfurt School of Finance and Management",
    marketingReferenceCoord: [],
    allAverages: [],
  }),
  persist: true,
  getters: {
    pricePerSquareMeter() {
      // Ensure that inputArea is not zero or null to avoid division by zero
      if (this.inputArea && this.inputArea !== 0) {
        return Math.round(this.maxPrice / this.inputArea); // Round the result
      }
      return null; // Return null or handle this case as needed
    },
    enableSearchButton() {
      if (this.searchParams.meanTransportation != "" && this.searchParams.housingType != "" && this.searchParams.maxRent != "" && this.searchParams.reference != "" && this.searchParams.travelTime != "") {
        return false
      } else {
        return true
      }
    },
    valueReference() {
      return this.searchParams.reference
    },
    availableChips() {
      const category = this.chipItems.find(
        (cat) => cat.category === this.selectedCategory
      );
      return category ? category.items : [];
    },
    availableChipsSearch() {
      return this.filteredChips.length > 0 ? this.filteredChips : this.chipItems.flatMap((cat) => cat.items);
    },
    getSelectedCategory() {
      return this.selectedCategory;
    },
    // For the recommender system.
    // Determine if it's a purchase based on the threshold
    isPurchase(state) {
      return state.maxPrice > state.rentVsPurchaseThreshold;
    },

    // Calculate price value based on whether it's rent or purchase
    //  Commenting this as it might be useful in the future if theres a use case in which we need to get similat neighbourhoods based on an analyzed address (as it has a min an max price. We are passing the max rent or purchase)
    // priceValue(state) {
    //   if (state.isPurchase) {
    //     console.log(state.inputArea)
    //     return state.maxPurchasePerSqM * state.inputArea;
    //   }
    //   console.log(state.inputArea)
    //   return state.maxRentPerSqM * state.inputArea;
    // },
    priceValue() {
      return this.maxPrice
    },
  },

  actions: {
    handlePageToShow(button) {
      this.userButtonSelection = button;
      this.handleSavePreferences()
    },
    handleOpen(button) {
      if (button === "analyzer") {
        this.housingAddress = ""
      }
      this.selectedButton = button
      this.setOpen(true); // Open the modal
    },
    setOpen(open) {
      this.isOpenPreferenceModal = open;
    },
    storeReferenceCoords(result) {
      // Initialize the train connection data with default values
      const trainConnectionData = [
        {
          globalCost: "", // Empty string as default
          globalTrainCategory: "", // Empty string as default
          publicTransportationCostPath: 0, // Default to 0
          walkingCostStartMeters: 0, // Default to 0
          walkingCostStartMinutes: 0, // Default to 0
          walkingCostEndMeters: 0, // Default to 0
          walkingCostEndMinutes: 0, // Default to 0
        }
      ];

      // Push the reference and related data to referencesResults array
      this.referencesResults.push({
        reference: result.place,
        // These are to make calculations
        refCoordLat: result.referenceCoords.lat,
        refCoordLng: result.referenceCoords.lng,
        // These are what we display in the map.
        referenceCoord: [result.referenceCoords.lat, result.referenceCoords.lng],
        timeBikeToReference: this.timeBikeToReference,
        timeTrainToReference: trainConnectionData,
        timeWalkingToReference: this.timeWalkingToReference,
        timeCarToReference: this.timeCarToReference,

      });
      console.log("References after push:", this.referencesResults);
      this.setMapCenter([result.referenceCoords.lat, result.referenceCoords.lng])
      // Cleaning for the next reference
      this.searchParams.reference = ''
      this.results = this.referencesFrankfurt
    },
    removeReference(index) {
      // Remove the item at the specified index
      this.referencesResults.splice(index, 1);

    },
    async getAllReferences() {
      const response = await HTTP.get("/references-frankfurt/", {
        headers: {
          'x-api-key': process.env.VUE_APP_API_ENDOPOINTS_KEY
        }
      });
      this.referencesFrankfurt = response.data
      console.log(this.referencesFrankfurt)
    },
    handleChange(event) {
      // console.log(event.target.value);
      const query = event.target.value.toLowerCase();
      // console.log(query);
      this.results = this.referencesFrankfurt.filter(
        (d) => d.reference.toLowerCase().indexOf(query) > -1
      );
    },
    handleChangeAmenityFilter(event) {
      const query = event.target.value.toLowerCase();
      if (query) {
        // Only filter if there is input in the search bar
        this.filteredChips = this.chipItems
          .flatMap((cat) => cat.items)
          .filter((chip) => chip.toLowerCase().includes(query));
      } else {
        // Clear filteredChips if no search input
        this.filteredChips = [];
      }
    },
    // Calculations
    toRadians(degrees) {
      return degrees * (Math.PI / 180);
    },
    haversineDistance(lat1, lon1, lat2, lon2) {
      const R = 6371000; // Radius of Earth in meters
      lat1 = this.toRadians(lat1);
      lon1 = this.toRadians(lon1);
      lat2 = this.toRadians(lat2);
      lon2 = this.toRadians(lon2);

      const deltaLat = lat2 - lat1;
      const deltaLon = lon2 - lon1;

      const a = Math.sin(deltaLat / 2) ** 2 + Math.cos(lat1) * Math.cos(lat2) * Math.sin(deltaLon / 2) ** 2;
      const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

      return R * c; // Distance in meters
    },
    latLonToMeters(lat1, lon1, lat2, lon2) {
      const dy = this.haversineDistance(lat1, lon1, lat2, lon1);
      const dx = this.haversineDistance(lat1, lon1, lat1, lon2);

      return { dx, dy };
    },
    estimateTime(lat1, lon1, lat2, lon2, speed) {
      const { dx, dy } = this.latLonToMeters(lat1, lon1, lat2, lon2);
      const manhattanDistance = dx + dy; // Sum of horizontal and vertical components
      const timeSeconds = manhattanDistance / speed;
      return timeSeconds / 60; // Convert seconds to minutes
    },
    createGoogleMapsUrl(lat, lon, heading = 0, pitch = 0) {
      return `https://www.google.com/maps/@?api=1&map_action=pano&viewpoint=${lat},${lon}&heading=${heading}&pitch=${pitch}`;
    },
    async analyzeListing(listing) {
      this.showLoadingAnalyzer = true
      try {
        this.housingAddress = ""
        this.housingAddressAnalyzed = ""
        // Removing listings polygon and marker to show the analysis results
        this.recommendedAreaPolygon = [];
        this.recommendedAreaCenter = [];
        this.isResultsPolyline = false;
        // Pasting area summary, polygons and price.
        this.areaSummary = listing.aiAreaDescription
        this.areaPolygon = listing.polygonLatLngs
        this.minRentPerSqM = listing.rentLowPrice
        this.maxRentPerSqM = listing.rentHighPrice
        this.minPurchasePerSqM = listing.purchaseLowPrice
        this.maxPurchasePerSqM = listing.purchaseHighPrice
        this.cityOrTown = listing.cityTown
        this.villageOrSubdivision = listing.villageSubdivision

        // You can now use this geocoded data in your application logic
        // For example, update your map or use the coordinates to fetch additional data
        const node_lat = listing.center.lat
        const node_lon = listing.center.lng

        // Create an array with node_lat_str and node_lon_str
        this.housingMarkerArray = [node_lat, node_lon];

        // Ensure polylineDataAnalyzer always has housingMarkerArray as the first item. Puhing the location for later.
        if (this.polylineDataAnalyzer.length === 0) {
          // Initialize with the housingMarkerArray
          this.polylineDataAnalyzer[0] = this.housingMarkerArray;
        } else {
          // Ensure housingMarkerArray is always the first element
          this.polylineDataAnalyzer[0] = this.housingMarkerArray;
        }

        // Update the payload with the geocoded coordinates
        this.payloadAmenityClosestCalculation.lat = listing.center.lat;
        this.payloadAmenityClosestCalculation.lng = listing.center.lng;
        // Now, send the updated payload to the backend
        const closestAmenitiesResponse = await HTTP.post(`/closest-items/`, this.payloadAmenityClosestCalculation, {
          headers: {
            'x-api-key': process.env.VUE_APP_API_ENDOPOINTS_KEY
          }
        });

        // Handle the response from the closest-items API call
        const closestAmenities = closestAmenitiesResponse.data;
        console.log(closestAmenities)
        // Populate selectedChips with the closest amenities
        this.selectedChips = [];
        closestAmenities.forEach((amenity) => {
          const coordinates = amenity.location.coordinates;
          this.selectedChips.push({
            name: amenity.name,
            category: amenity.category,
            amenityCoord: [coordinates[1], coordinates[0]],  // Lat/Lng from MongoDB coordinates
            amenityCoordLat: coordinates[1],
            amenityCoordLng: coordinates[0],
            timeBikeToAmenity: null,
            timeWalkingToAmenity: null,
            timeCarToAmenity: null
          });
        });

        // START NEW CODE
        // **Fix: Declare these variables in the outer scope**
        let totalLat = node_lat;
        let totalLon = node_lon;
        let count = 1;

        // **Prepare Train Paths Requests**
        const trainPathsRequests = this.referencesResults.map(async (referenceObject) => {
          const refLat = parseFloat(referenceObject.refCoordLat);
          const refLon = parseFloat(referenceObject.refCoordLng);

          // Update totals **inside the mapped function**
          totalLat += refLat;
          totalLon += refLon;
          count += 1;

          referenceObject.timeBikeToReference = Math.round(this.estimateTime(node_lat, node_lon, refLat, refLon, this.averageBicycleSpeed));
          referenceObject.timeWalkingToReference = Math.round(this.estimateTime(node_lat, node_lon, refLat, refLon, this.averageWalkingSpeed));
          referenceObject.timeCarToReference = Math.round(this.estimateTime(node_lat, node_lon, refLat, refLon, this.averageCarSpeed));

          try {
            // Call the existing getCalculatedPath function
            const trainTimes = await this.getCalculatedPath(node_lat, node_lon, refLat, refLon);
            console.log(trainTimes.data)
            referenceObject.timeTrainToReference = trainTimes.data;
          } catch (error) {
            console.error(`Error fetching train paths for ${referenceObject.reference}:`, error);
            referenceObject.timeTrainToReference = 0;
          }
        });

        // Execute all API calls in parallel
        await Promise.all(trainPathsRequests);
        // END NEW CODE
        // Now estimate travel times
        this.selectedChips.forEach((amenityObject) => {
          amenityObject.timeBikeToAmenity = Math.round(this.estimateTime(
            node_lat, node_lon, amenityObject.amenityCoordLat, amenityObject.amenityCoordLng, this.averageBicycleSpeed));
          amenityObject.timeWalkingToAmenity = Math.round(this.estimateTime(
            node_lat, node_lon, amenityObject.amenityCoordLat, amenityObject.amenityCoordLng, this.averageWalkingSpeed));
          amenityObject.timeCarToAmenity = Math.round(this.estimateTime(
            node_lat, node_lon, amenityObject.amenityCoordLat, amenityObject.amenityCoordLng, this.averageCarSpeed));
        });
        console.log(this.selectedChips)
        // Calculate the mean latitude and longitude
        const meanLat = totalLat / count;
        const meanLon = totalLon / count;

        // Update mapCenter with the mean coordinates
        this.mapCenter = [meanLat, meanLon];


        // Additional calculations or updates can be done here
        this.googleMapsView = this.createGoogleMapsUrl(node_lat, node_lon)

        // Zooming out a little bit
        // Set a small delay before triggering the mapZoom update
        setTimeout(() => {
          this.showLoadingAnalyzer = false
          this.mapZoom = 12;
        }, 500); // 500 milliseconds delay, adjust as needed
        this.clickedAnalyzeNeighbouhood = true


        // Use a short delay to ensure the reset is processed
        setTimeout(() => {
          this.userButtonSelection = 'analyze'
        }, 50); // 50ms delay is usually sufficient
        this.handleSavePreferences()
        console.log(this.referencesResults)
        console.log(this.selectedChips)
      } catch (error) {
        this.showLoadingAnalyzer = false

        this.addressNotFoundAlert = true


        console.error('Error analyzing address:', error.response);
        // Handle errors, such as address not found or request failure
      }
    },

    async analyzeAddress() {
      this.setOpen(false)
      this.showLoadingAnalyzer = true
      try {
        // Replace `this.housingAddress` with the actual address variable you're sending to the backend
        const address = this.housingAddress;

        // Call the FastAPI endpoint with the address as a query parameter
        const responseGeocodedAddress = await HTTP.get(`/geocode-address/`, {
          params: { address },  // Send the address as a query parameter
          headers: {
            'x-api-key': process.env.VUE_APP_API_ENDOPOINTS_KEY
          }
        });
        console.log(responseGeocodedAddress.data)
        // Extract the geocoded data from the response
        const geocodedData = responseGeocodedAddress.data.coord

        // Pasting area summary, polygons and price.
        this.areaSummary = responseGeocodedAddress.data.document.aiAreaDescription
        this.areaPolygon = responseGeocodedAddress.data.document.polygonLatLngs
        this.minRentPerSqM = responseGeocodedAddress.data.document.rentLowPrice
        this.maxRentPerSqM = responseGeocodedAddress.data.document.rentHighPrice
        this.minPurchasePerSqM = responseGeocodedAddress.data.document.purchaseLowPrice
        this.maxPurchasePerSqM = responseGeocodedAddress.data.document.purchaseHighPrice
        this.cityOrTown = responseGeocodedAddress.data.document.cityTown
        this.villageOrSubdivision = responseGeocodedAddress.data.document.villageSubdivision

        // You can now use this geocoded data in your application logic
        // For example, update your map or use the coordinates to fetch additional data
        const node_lat = geocodedData.lat
        const node_lon = geocodedData.lng

        // Create an array with node_lat_str and node_lon_str
        this.housingMarkerArray = [node_lat, node_lon];

        // Ensure polylineDataAnalyzer always has housingMarkerArray as the first item. Puhing the location for later.
        if (this.polylineDataAnalyzer.length === 0) {
          // Initialize with the housingMarkerArray
          this.polylineDataAnalyzer[0] = this.housingMarkerArray;
        } else {
          // Ensure housingMarkerArray is always the first element
          this.polylineDataAnalyzer[0] = this.housingMarkerArray;
        }

        // Update the payload with the geocoded coordinates
        this.payloadAmenityClosestCalculation.lat = geocodedData.lat;
        this.payloadAmenityClosestCalculation.lng = geocodedData.lng;
        // Now, send the updated payload to the backend
        const closestAmenitiesResponse = await HTTP.post(`/closest-items/`, this.payloadAmenityClosestCalculation, {
          headers: {
            'x-api-key': process.env.VUE_APP_API_ENDOPOINTS_KEY
          }
        });

        // Handle the response from the closest-items API call
        const closestAmenities = closestAmenitiesResponse.data;
        console.log(closestAmenities)
        // Populate selectedChips with the closest amenities
        this.selectedChips = [];
        closestAmenities.forEach((amenity) => {
          const coordinates = amenity.location.coordinates;
          this.selectedChips.push({
            name: amenity.name,
            category: amenity.category,
            amenityCoord: [coordinates[1], coordinates[0]],  // Lat/Lng from MongoDB coordinates
            amenityCoordLat: coordinates[1],
            amenityCoordLng: coordinates[0],
            timeBikeToAmenity: null,
            timeWalkingToAmenity: null,
            timeCarToAmenity: null
          });
        });

        // START NEW CODE
        // **Fix: Declare these variables in the outer scope**
        let totalLat = node_lat;
        let totalLon = node_lon;
        let count = 1;

        // **Prepare Train Paths Requests**
        const trainPathsRequests = this.referencesResults.map(async (referenceObject) => {
          const refLat = parseFloat(referenceObject.refCoordLat);
          const refLon = parseFloat(referenceObject.refCoordLng);

          // Update totals **inside the mapped function**
          totalLat += refLat;
          totalLon += refLon;
          count += 1;

          referenceObject.timeBikeToReference = Math.round(this.estimateTime(node_lat, node_lon, refLat, refLon, this.averageBicycleSpeed));
          referenceObject.timeWalkingToReference = Math.round(this.estimateTime(node_lat, node_lon, refLat, refLon, this.averageWalkingSpeed));
          referenceObject.timeCarToReference = Math.round(this.estimateTime(node_lat, node_lon, refLat, refLon, this.averageCarSpeed));

          try {
            // Call the existing getCalculatedPath function
            const trainTimes = await this.getCalculatedPath(node_lat, node_lon, refLat, refLon);
            console.log(trainTimes.data)
            referenceObject.timeTrainToReference = trainTimes.data;
          } catch (error) {
            console.error(`Error fetching train paths for ${referenceObject.reference}:`, error);
            referenceObject.timeTrainToReference = 0;
          }
        });

        // Execute all API calls in parallel
        await Promise.all(trainPathsRequests);
        // END NEW CODE
        // Now estimate travel times
        this.selectedChips.forEach((amenityObject) => {
          amenityObject.timeBikeToAmenity = Math.round(this.estimateTime(
            node_lat, node_lon, amenityObject.amenityCoordLat, amenityObject.amenityCoordLng, this.averageBicycleSpeed));
          amenityObject.timeWalkingToAmenity = Math.round(this.estimateTime(
            node_lat, node_lon, amenityObject.amenityCoordLat, amenityObject.amenityCoordLng, this.averageWalkingSpeed));
          amenityObject.timeCarToAmenity = Math.round(this.estimateTime(
            node_lat, node_lon, amenityObject.amenityCoordLat, amenityObject.amenityCoordLng, this.averageCarSpeed));
        });
        console.log(this.selectedChips)
        // Calculate the mean latitude and longitude
        const meanLat = totalLat / count;
        const meanLon = totalLon / count;

        // Update mapCenter with the mean coordinates
        this.mapCenter = [meanLat, meanLon];


        // Additional calculations or updates can be done here
        this.googleMapsView = this.createGoogleMapsUrl(node_lat, node_lon)
        // Zooming out a little bit
        // Set a small delay before triggering the mapZoom update
        setTimeout(() => {
          this.mapZoom = 13;
        }, 500); // 500 milliseconds delay, adjust as needed
        this.clickedAnalyzeNeighbouhood = true
        // Reset the accordion group value to force reactivity

        this.housingAddressAnalyzed = this.housingAddress

        // Use a short delay to ensure the reset is processed
        setTimeout(() => {
          this.showLoadingAnalyzer = false
        }, 50); // 50ms delay is usually sufficient
        this.handleSavePreferences()
        console.log(this.referencesResults)
        console.log(this.selectedChips)
      } catch (error) {
        this.showLoadingAnalyzer = false

        this.addressNotFoundAlert = true

        console.error('Error analyzing address:', error.response);
        // Handle errors, such as address not found or request failure
      }
    },
    subtractOne(reference) {
      // Find the index of the item with the given reference
      const index = this.referencesResults.findIndex(r => r.reference === reference);

      // If the item is found, remove it from the array
      if (index !== -1) {
        this.referencesResults.splice(index, 1);
      }

      // Check if there are remaining references
      if (this.referencesResults.length > 0) {
        // Set the map center to the first remaining reference
        this.mapCenter = this.referencesResults[0].referenceCoord;
      } else {
        // If no references remain, reset the map center to the initial value
        this.mapCenter = this.initialMapCenter;
      }

    },
    toggleChip(chipName) {
      // Find the category for the selected chip
      const categoryObj = this.chipItems.find((category) => category.items.includes(chipName));

      if (!categoryObj) {
        console.error("No matching category found for the chip:", chipName);
        return;
      }

      const category = categoryObj.category;

      // Automatically update the selectedCategory to activate the corresponding segment
      this.selectedCategory = category;


      // Check if the chip is already selected, and toggle it
      const index = this.selectedChips.findIndex(selectedChip => selectedChip.name === chipName && selectedChip.category === category);
      if (index === -1) {
        // Add the selected chip to the list
        this.selectedChips.push({
          name: chipName,
          category: category
        });
      } else {
        // Remove the chip from the selected list if it is already selected
        this.selectedChips.splice(index, 1);
      }
      // console.log(this.selectedChips);

      // Create the payload after toggling
      this.createPayload();
    },

    createPayload() {
      // Create the structure for the payload based on selectedChips
      const selections = {};

      this.selectedChips.forEach(chip => {
        if (!selections[chip.category]) {
          selections[chip.category] = [];
        }
        selections[chip.category].push(chip.name);
      });

      // Build the final payload
      const payload = {
        lat: null,
        lng: null,
        selections: selections
      };
      this.payloadAmenityClosestCalculation = payload
      // console.log(this.payloadAmenityClosestCalculation)
    },
    setCategory(category) {
      this.selectedCategory = category;
    },
    isSelected(chipName) {
      return this.selectedChips.some(selectedChip => selectedChip.name === chipName);
    },
    // RECOMMENDATION CHIPS
    toggleChipRecommendations(chip) {
      // Update the Pinia store's selectedMode directly
      this.selectedMode = chip;
    },
    isSelectedRecommendationChip(chipName) {
      // Check if the chip is the current selectedMode in the Pinia store
      return this.selectedMode === chipName;
    },
    setMapCenter(center) {
      this.mapCenter = center;
    },
    resetMapCenter() {
      this.mapCenter = this.initialMapCenter;
    },
    // Method to handle ionChange event and update the boolean value
    toggleAreaSummary(event) {
      this.isAreaSummaryEnabled = event.detail.checked;
    },
    async getRecommendations() {
      this.setOpen(false)
      this.showLoading = true
      try {
        const response = await HTTP.get(`/get-recommendations/`, {
          params: {
            center_lat: this.referencesResults[0].refCoordLat, // To do: Get the lat from the farthest reference (?) Maybe that for now, later the average of all references?
            center_lng: this.referencesResults[0].refCoordLng, // To do. See the comment in the line above
            is_purchase: this.isPurchase,
            price_value: this.priceValue,
            input_area: this.inputArea,
            travel_time: this.maxTravelTime,
            mode: this.selectedMode, // New parameter for mode of transportation
          },
          headers: {
            'x-api-key': process.env.VUE_APP_API_ENDOPOINTS_KEY
          },
        });

        // Extract lower and higher price recommendations from the response
        let lowerRecommendations = response.data.lower_price_recommendations || [];
        let higherRecommendations = response.data.higher_price_recommendations || [];

        // Filter out the current analyzed neighborhood from both lists
        this.recommendedNeighbourhoodsLow = lowerRecommendations.filter(
          (neighborhood) =>
            neighborhood.villageSubdivision !== this.villageOrSubdivision ||
            neighborhood.cityTown !== this.cityOrTown
        );

        this.recommendedNeighbourhoodsHigh = higherRecommendations.filter(
          (neighborhood) =>
            neighborhood.villageSubdivision !== this.villageOrSubdivision ||
            neighborhood.cityTown !== this.cityOrTown
        );

        // Remove duplicates between low and high recommendations
        this.recommendedNeighbourhoodsHigh = this.recommendedNeighbourhoodsHigh.filter(
          (neighborhood) =>
            !this.recommendedNeighbourhoodsLow.some(
              (low) =>
                low.villageSubdivision === neighborhood.villageSubdivision &&
                low.cityTown === neighborhood.cityTown
            )
        );

        console.log("Lower Recommendations:", this.recommendedNeighbourhoodsLow);
        console.log("Higher Recommendations:", this.recommendedNeighbourhoodsHigh);
        this.showLoading = false
        // **Prepare API calls for train travel times**
        const travelTimeRequests = [...this.recommendedNeighbourhoodsLow, ...this.recommendedNeighbourhoodsHigh].map(
          async (neighbourhoodCenter) => {
            const { lat, lng } = neighbourhoodCenter.center;

            // Fetch actual train time from API
            try {
              const trainTimes = await this.getCalculatedPath(lat, lng, this.referencesResults[0].refCoordLat, this.referencesResults[0].refCoordLng);
              console.log(trainTimes.data)
              neighbourhoodCenter.timeTrainToReference = trainTimes.data; // Use number of routes found
            } catch (error) {
              console.error(`Error fetching train paths for ${neighbourhoodCenter.cityTown}:`, error);
              neighbourhoodCenter.timeTrainToReference = 0;
            }
            // Add other estimated travel times
            neighbourhoodCenter.timeBikeToReference = Math.round(
              this.estimateTime(lat, lng, this.referencesResults[0].refCoordLat, this.referencesResults[0].refCoordLng, this.averageBicycleSpeed)
            );
            neighbourhoodCenter.timeWalkingToReference = Math.round(
              this.estimateTime(lat, lng, this.referencesResults[0].refCoordLat, this.referencesResults[0].refCoordLng, this.averageWalkingSpeed)
            );
            neighbourhoodCenter.timeCarToReference = Math.round(
              this.estimateTime(lat, lng, this.referencesResults[0].refCoordLat, this.referencesResults[0].refCoordLng, this.averageCarSpeed)
            );
          }
        );

        // Execute all API calls in parallel
        await Promise.all(travelTimeRequests);

        // // Adding travel times to each location object
        // [...this.recommendedNeighbourhoodsLow, ...this.recommendedNeighbourhoodsHigh].forEach((neighbourhoodCenter) => {
        //   const { lat, lng } = neighbourhoodCenter.center;
        //   // Add the estimated travel times to the location object
        //   neighbourhoodCenter.timeBikeToReference = Math.round(
        //     this.estimateTime(
        //       lat,
        //       lng,
        //       this.referencesResults[0].refCoordLat,
        //       this.referencesResults[0].refCoordLng,
        //       this.averageBicycleSpeed
        //     )
        //   );
        //   neighbourhoodCenter.timeTrainToReference = 38; // Fixed time for train as per your setup
        //   neighbourhoodCenter.timeWalkingToReference = Math.round(
        //     this.estimateTime(
        //       lat,
        //       lng,
        //       this.referencesResults[0].refCoordLat,
        //       this.referencesResults[0].refCoordLng,
        //       this.averageWalkingSpeed
        //     )
        //   );
        //   neighbourhoodCenter.timeCarToReference = Math.round(
        //     this.estimateTime(
        //       lat,
        //       lng,
        //       this.referencesResults[0].refCoordLat,
        //       this.referencesResults[0].refCoordLng,
        //       this.averageCarSpeed
        //     )
        //   );
        // });

        console.log("Neighborhoods with Travel Times (Low + High):", [
          ...this.recommendedNeighbourhoodsLow,
          ...this.recommendedNeighbourhoodsHigh,
        ]);


        // Use a short delay to ensure the reset is processed
        setTimeout(() => {
        }, 50); // 50ms delay is usually sufficient
        this.handleSavePreferences()
      } catch (error) {
        this.showLoading = false
        this.neighbourhoodsNotFoundAlert = true
        console.error("Failed to fetch recommendations", error);
      }
    },
    // openPreferences() {
    //   // Reset the accordion group value to force reactivity
    //   this.accordionGroupValue = null;
    //   this.accordionGroupValue = "first";

    // },
    showRecommendedArea(neighborhood) {
      this.mapCenter = [neighborhood.center.lat, neighborhood.center.lng];
      this.recommendedAreaPolygon = neighborhood.polygonLatLngs
      this.recommendedAreaCenter = [neighborhood.center.lat, neighborhood.center.lng]
      this.recommendedAreaSummary = neighborhood.aiAreaDescription
      this.recommendedAreaVillageSubdivision = neighborhood.villageSubdivision
      this.recommendedAreaCityTown = neighborhood.cityTown
      // Travel times by mean of transportation
      this.recommendedAreaTrainTimeToReference = neighborhood.timeTrainToReference
      this.recommendedAreaBicycleTimeToReference = neighborhood.timeBikeToReference
      this.recommendedAreaCarTimeToReference = neighborhood.timeCarToReference
      this.recommendedAreaWalkingTimeToReference = neighborhood.timeWalkingToReference
    },
    showListingArea(neighborhood) {
      this.mapCenter = [];
      this.mapCenter = [neighborhood.center.lat, neighborhood.center.lng];
      this.recommendedAreaPolygon = [];
      this.recommendedAreaPolygon = neighborhood.polygonLatLngs
      this.recommendedAreaCenter = [neighborhood.center.lat, neighborhood.center.lng]
      this.recommendedAreaSummary = neighborhood.aiAreaDescription
      this.recommendedAreaVillageSubdivision = neighborhood.villageSubdivision
      this.recommendedAreaCityTown = neighborhood.cityTown
    },
    toggleShowMore() {
      this.showFullText = !this.showFullText;
    },
    updatePolyline(item, type) {
      this.polylineDataAnalyzer = []
      this.isResultsPolyline = true;
      this.isRecommendedPolyline = false; // Ensure only one is active at a time
      console.log('Selected item:', item, 'Type:', type);

      // Determine the lat/lng keys based on the type
      const latKey = type === 'amenity' ? 'amenityCoordLat' : 'refCoordLat';
      const lngKey = type === 'amenity' ? 'amenityCoordLng' : 'refCoordLng';

      // Reassign the entire array to ensure reactivity
      this.polylineDataAnalyzer = [
        this.housingMarkerArray, // Constant first element
        [item[latKey], item[lngKey]], // Dynamic second element
      ];

      console.log('Updated polylineDataAnalyzer:', this.polylineDataAnalyzer);
    },
    updatePolylineRecommended(item) {

      this.isResultsPolyline = false;
      this.isRecommendedPolyline = true;
      this.polylineDataRecommender = [];
      // Reassign the array
      this.polylineDataRecommender = [
        [this.referencesResults[0].refCoordLat, this.referencesResults[0].refCoordLng], // Constant first element
        [item.center.lat, item.center.lng], // Dynamic second element
      ];
    },
    async getUserPreferences(userIdentifier) {
      try {
        const response = await HTTP.get(`/userSearchPreferences/${userIdentifier}`, {
          headers: {
            "x-api-key": process.env.VUE_APP_API_ENDOPOINTS_KEY,
          },
        });
        console.log(response.data.referencesResults)
        console.log(response.data.selectedChips)
        if (response.status === 200 && response.data) {
          this.referencesResults = response.data.referencesResults,
            this.selectedChips = response.data.selectedChips,
            // this.selectedChips = response.data.selectedChips, -> This will also make appear the amenities there (from the previous analyzed address). Might be useful later.
            this.selectedMode = response.data.selectedMode,
            this.maxTravelTime = response.data.maxTravelTime,
            this.maxPrice = response.data.maxPrice,
            this.inputArea = response.data.inputArea,
            // Must update the payload with the pulled selected chips. Otherwise, when aanalyazing an address, it wont return anything for amenities.
            this.createPayload();
          console.log("User preferences retrieved successfully:", response.data);
        }
      } catch (error) {
        if (error.response && error.response.status === 404) {
          this.referencesResults = []
          this.selectedChips = []
          this.selectedMode = ""
          this.maxTravelTime = null
          this.maxPrice = null
          this.inputArea = null
          console.error("User preferences not found.");
        } else {
          console.error("Error fetching user preferences:", error);
        }
        return null; // Return null if preferences are not found or an error occurs
      }
    },
    async getAllGrundData() {
      try {
        const response = await HTTP.get("/published-allgrund/", {
          headers: {
            "x-api-key": process.env.VUE_APP_API_ENDOPOINTS_KEY,
          },
        });

        if (response.status === 200 && response.data) {
          console.log("Published housing listings retrieved successfully:", response.data);
          this.listingsData = response.data
        }
      } catch (error) {
        if (error.response && error.response.status === 404) {
          console.error("No published housing listings found.");
        } else {
          console.error("Error fetching published housing listings:", error);
        }
        return null; // Return null if an error occurs
      }
    },
    async handleSavePreferences() {
      this.setOpen(false)
      this.setSelectedPreferenceView = ""
      const userIdentifier = useAuthStore().user.uid; // Use userIdentifier from the authenticated user

      const payload = {
        userIdentifier: userIdentifier,
        referencesResults: this.referencesResults,
        selectedChips: this.selectedChips,
        selectedMode: this.selectedMode,
        maxTravelTime: this.maxTravelTime,
        maxPrice: this.maxPrice,
        inputArea: this.inputArea,
      };


      try {
        // Check if preferences exist by userIdentifier
        const checkResponse = await HTTP.get(`/userSearchPreferences/${userIdentifier}`, {
          headers: {
            "x-api-key": process.env.VUE_APP_API_ENDOPOINTS_KEY,
          },
        });

        if (checkResponse.status === 200 && checkResponse.data) {
          // Update existing preferences
          const response = await HTTP.put(`/userSearchPreferences/${userIdentifier}`, payload, {
            headers: {
              "x-api-key": process.env.VUE_APP_API_ENDOPOINTS_KEY,
            },
          });
          if (response.status === 200) {
            console.log("Preferences updated successfully");
          }
        }

      } catch (error) {
        if (error.response && error.response.status === 404) {
          // Create new preferences if not found
          try {
            const response = await HTTP.post("/userSearchPreferences/", payload, {
              headers: {
                "x-api-key": process.env.VUE_APP_API_ENDOPOINTS_KEY,
              },
            });
            if (response.status === 200) {
              console.log("Preferences created successfully");
            }
          } catch (createError) {
            console.error("Error creating preferences:", createError);
            // this.showNetworkErrorAdAlert = true;
          }
        } else {
          console.error("Network error:", error);
          // this.showNetworkErrorAdAlert = true;
        }
      }
    },
    // Example API call in JavaScript
    async getCalculatedPath(startLat, startLng, endLat, endLng) {
      const response = await HTTP.get(`/calculate-path/?start_lat=${startLat}&start_lng=${startLng}&end_lat=${endLat}&end_lng=${endLng}`, {
        headers: {
          'x-api-key': process.env.VUE_APP_API_ENDOPOINTS_KEY,
        }
      });
      console.log(response.data);
      return response;
    },
    closeModalApplyListing() {
      this.showModalApplyListing = false
    },
    openApplyFormListing() {
      this.showModalApplyListing = true
    },
    sortAds(ev) {
      console.log(ev.detail.value)
      this.sortValue = ev.detail.value;
      const sortCondition = ev.detail.value;

      this.listingsData = [...this.listingsData].sort((a, b) => {
        if (sortCondition === "totalRent") {
          const rentA = a.rentInfo?.warmRent ?? Infinity;
          const rentB = b.rentInfo?.warmRent ?? Infinity;
          return rentA - rentB;
        } else if (sortCondition === "area") {
          const areaA = a.area ?? Infinity;
          const areaB = b.area ?? Infinity;
          return areaA - areaB;
        }
        return 0;
      });
    },
    closeAlertAddressNotFound() {
      this.addressNotFoundAlert = false
      this.handleOpen('analyzer')
    },
    closeAlertRecommendationsNotFound() {
      this.neighbourhoodsNotFoundAlert = false
      this.handleOpen('recommendations')
    },
    openPublishAd() {
      this.publishAdAlert = true
    },
    closePublishAd() {
      this.publishAdAlert = false
    },
    handleCommuteDestinationChange(ev) {
      this.segmentCommuteDestination = ev.detail.value;
    },
    async addAddressToReferences() {
      this.showLoadingDestinationAddress = true
      try {

        const address = this.destinationAddress;

        // Call the FastAPI endpoint with the address as a query parameter
        const responseGeocodedAddress = await HTTP.get(`/geocode-address/`, {
          params: { address },  // Send the address as a query parameter
          headers: {
            'x-api-key': process.env.VUE_APP_API_ENDOPOINTS_KEY
          }
        });
        console.log(responseGeocodedAddress.data);

        // Extract the geocoded data from the response
        const geocodedData = responseGeocodedAddress.data.coord;

        const node_lat = geocodedData.lat;
        const node_lon = geocodedData.lng;

        const trainConnectionData = [
          {
            globalCost: "", // Empty string as default
            globalTrainCategory: "", // Empty string as default
            publicTransportationCostPath: 0, // Default to 0
            walkingCostStartMeters: 0, // Default to 0
            walkingCostStartMinutes: 0, // Default to 0
            walkingCostEndMeters: 0, // Default to 0
            walkingCostEndMinutes: 0, // Default to 0
          }
        ];


        // Push the new reference data to referencesResults
        this.referencesResults.push({
          reference: this.destinationName,  // Use the destinationName as the reference
          refCoordLat: node_lat,            // Latitude from geocoded data
          refCoordLng: node_lon,            // Longitude from geocoded data
          referenceCoord: [node_lat, node_lon],  // Coordinate array for the map
          timeBikeToReference: this.timeBikeToReference,
          timeTrainToReference: trainConnectionData,
          timeWalkingToReference: this.timeWalkingToReference,
          timeCarToReference: this.timeCarToReference
        });

        console.log("References after push:", this.referencesResults);

        // Update the map center (if needed)
        this.setMapCenter([node_lat, node_lon]);
        this.showLoadingDestinationAddress = false

        // Clean up for the next reference
        this.destinationAddress = "";  // Reset destination address
        this.destinationName = "";     // Reset destination name
      } catch (error) {
        this.showLoadingDestinationAddress = false
        this.destinationAddressNotFoundAlert = true

      }
    },
    closeAlertDestinationAddressNotFound() {
      this.destinationAddressNotFoundAlert = false
    },
    setSelectedPreference(preferenceSelected) {
      this.setOpen(false)
      this.setSelectedPreferenceView = preferenceSelected;
    },
    clearUserPreferences() {
      this.referencesResults = [];
      this.selectedChips = [];
      this.selectedMode = '';
      this.maxTravelTime = null;
      this.maxPrice = null;
      this.inputArea = null;
    },
  }
})
